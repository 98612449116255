var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-ad",style:('letter-spacing:' +
    _vm.annStyle.letterSpacing +
    ';padding-bottom:' +
    _vm.annStyle.paddingBottom +
    ';padding-top:' +
    _vm.annStyle.paddingTop +
    ';padding-right:' +
    _vm.annStyle.paddingRight +
    ';padding-left:' +
    _vm.annStyle.paddingLeft +
    ';line-height:' +
    _vm.annStyle.lineHeight +
    ';font-size:' +
    _vm.annStyle.fontSize +
    ';text-align: ' +
    _vm.textAlign +
    ';background:' +
    _vm.backgroundColor +
    ';color:' +
    _vm.color +
    ';font-weight:' +
    _vm.fontWeight +
    ';border-top:' +
    _vm.borderTop +
    ';border-bottom:' +
    _vm.borderBottom)},[_c('div',{staticClass:"text-pre"},[_vm._v(_vm._s(_vm.content))])])
}
var staticRenderFns = []

export { render, staticRenderFns }