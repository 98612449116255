/**
 * @desc 函数节流
 * @param func 函数
 * @param wait 延迟执行毫秒数
 */
export const throttle = (func, wait = 200) => {
  return function (...args) {
    const now = +new Date(); // 当前时间
    // 将当前时间和上⼀一次执⾏行行函数时间对⽐比
    // 如果差值⼤大于设置的等待时间就执⾏行行函数
    console.log(func.lastTime, now, wait);
    if (!func.lastTime) {
      func.lastTime = 0;
    }
    console.log(func.lastTime, now, wait, 'a11111111111');
    if (now - func.lastTime > wait) {
      func.lastTime = now;
      // console.log('[warning]', 'debounce throttle func.apply');
      func.apply(this, args);
    } else {
      // console.log('[warning]', 'debounce throttle interrupt !!!');
    }
  };
};
/**
 * @desc 函数节流2
 * @param func 函数
 * @param wait 延迟执行毫秒数
 */
// 上一次执⾏行行该函数的时间
let lastTime = 0;
export const throttleAt = (func, wait = 200) => {
  return function (...args) {
    console.log(lastTime);
    console.log(new Date());
    const now = +new Date(); // 当前时间
    // 将当前时间和上⼀一次执⾏行行函数时间对⽐比
    // 如果差值⼤大于设置的等待时间就执⾏行行函数
    if (now - lastTime > wait) {
      lastTime = now;
      // console.log('[warning]', 'debounce throttle func.apply');
      func.apply(this, args);
    } else {
      // console.log('[warning]', 'debounce throttle interrupt !!!');
    }
  };
};
/**
 * @desc 函数防抖
 * @param func 函数
 * @param wait 延迟执行毫秒数
 * @param immediate true 表立即执行，false 表非立即执行
 */
export function debounce(func, wait = 200, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;

    if (timeout) clearTimeout(timeout);
    if (immediate) {
      const callNow = !timeout;
      timeout = setTimeout(() => {
        timeout = null;
      }, wait);
      if (callNow) func.apply(context, args);
    } else {
      timeout = setTimeout(() => {
        func.apply(context, args);
      }, wait);
    }
  };
}

