<template>
  <div
    class="text-ad"
    :style="
      'letter-spacing:' +
      annStyle.letterSpacing +
      ';padding-bottom:' +
      annStyle.paddingBottom +
      ';padding-top:' +
      annStyle.paddingTop +
      ';padding-right:' +
      annStyle.paddingRight +
      ';padding-left:' +
      annStyle.paddingLeft +
      ';line-height:' +
      annStyle.lineHeight +
      ';font-size:' +
      annStyle.fontSize +
      ';text-align: ' +
      textAlign +
      ';background:' +
      backgroundColor +
      ';color:' +
      color +
      ';font-weight:' +
      fontWeight +
      ';border-top:' +
      borderTop +
      ';border-bottom:' +
      borderBottom
    "
  >
    <div class="text-pre">{{ content }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  components: {},
  props: {
    content: String,
    textAlign: {
      type: String,
      default: 'center',
    },
    backgroundColor: {
      type: String,
      default: '#ffffff',
    },
    color: {
      type: String,
      default: '#222',
    },
    borderTop: {
      type: String,
      default: '',
    },
    fontSize: {
      type: Number,
      default: 14,
    },
    letterSpacing: {
      type: Number,
      default: 0,
    },
    lineHeight: {
      type: Number,
      default: 0,
    },
    innerSpace: {
      type: Number,
      default: 10,
    },
    siderSpace: {
      type: Number,
      default: 10,
    },
    borderBottom: {
      type: String,
      default: '',
    },
    fontWeight: {
      type: Number,
      default: 400,
    },
    item: Object,
  },
  computed: {
    annStyle() {
      return {
        letterSpacing: `${this.letterSpacing}px`,
        lineHeight: `${this.lineHeight}px`,
        paddingLeft: `${this.innerSpace}px`,
        paddingRight: `${this.innerSpace}px`,
        paddingTop: `${this.siderSpace}px`,
        paddingBottom: `${this.siderSpace}px`,
        fontSize: `${this.fontSize}px`,
      };
    },
  },
  methods: {},
};
</script>
<style>
.text-ad {
  width: 100%;
  line-height: 20px;
  padding: 10px;
  margin: 0 auto;
  text-align: center;
  color: #222;
  font-size: 14px;
  background: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  white-space: normal;
}
.text-pre {
  white-space: pre-wrap;
}
</style>