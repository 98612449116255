import request, { withExtTenantIdRequest } from '@/utils/request';

export function modifyPassword(mobile, verificationCode, password) {
  return request({
    url: `/user/api/user/forgetPassword?mobile=${mobile}&verificationCode=${verificationCode}&password=${password}`,
    method: 'post'
  });
}

export function register(mobile, verificationCode, password) {
  return request({
    url: `/newretail/api/auth/register?mobile=${mobile}&verificationCode=${verificationCode}&password=${password}`,
    method: 'post'
  });
}

// 根据手机号获取验证码
export function getCode(mobile) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/verifyCode/send?mobile=${mobile}`,
    method: 'post'
  });
}

// 新接口 短信发送接口（移动端)  接入网易行为码
export function sendVerifyCodeWithActionCode(data = {}) {
  const postData = {
    content: '【御家微商城】，您收到的验证码为：{$verifyCode},请勿泄露给其他人',
    ...data,
  };
  return withExtTenantIdRequest({
    url: `/sms/api/smsApp/sendVerifyCodeWithActionCode`,
    method: 'post',
    data: postData
  });
}