<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" label-width="100px" ref="ruleForm" class="ruleForm">
      <input type="password" hidden autocomplete="new-password" />
      <el-form-item label="手机号:" prop="mobile">
        <el-input v-model="ruleForm.mobile" class="ruleForm-mobile"> </el-input>
        <div class="ruleForm-mobile-text">+86</div>
      </el-form-item>
      <el-form-item label="验证码:" prop="verifyCode">
        <el-input class="ruleForm-verifyCode" v-model="ruleForm.verifyCode"></el-input>
        <getVerificationCode
          :mobile="ruleForm.mobile"
          class="ruleForm-verifyCode-text"
          :handleCallback="mobileCheck"
        />
      </el-form-item>
      <el-form-item label="登录密码:" prop="password">
        <el-input type="password" v-model="ruleForm.password"></el-input>
      </el-form-item>
      <el-form-item label="确认密码:" prop="confirmPassword">
        <el-input type="password" v-model="ruleForm.confirmPassword"></el-input>
      </el-form-item>
      <p class="ruleForm-text">*建议设置密码为6-12位</p>
      <p class="ruleForm-text">数字、字母、特殊字符任意至少两种的组合</p>
      <div class="ruleForm-row">
        <el-checkbox v-model="agreest">
          <span class="form-row--txetbox">已阅读并同意</span></el-checkbox
        >
        <span class="link-type" @click="openProtocol('xy')">《平台使用协议》</span>和

        <span class="link-type" @click="openProtocol('gf')">《分销商管理规范》</span>、
        <span class="link-type" @click="openProtocol('ys')">《隐私协议》</span>
      </div>
      <el-form-item>
        <el-button
          :loading="saveLoading"
          @click="submitForm('ruleForm')"
          class="ruleForm-btn"
          type="primary"
          >下一步</el-button
        >
      </el-form-item>
    </el-form>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisibleProtocol"
      width="1000px"
      top="10vh"
      :before-close="() => (dialogVisibleProtocol = false)"
    >
      <div class="ruleForm-textBox">
        <!-- <iframe :src="iframeSrc" frameborder="0" width="100%" height="100%"></iframe> -->
        <agreement :agreementType="agreementType" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import { getCode } from '@/api/system/user';
import getVerificationCode from '@/components/getVerificationCode';
import { memberAuthMobileVerify, memberSourceCreate } from '@/api/login';
import { validatePhoneSimple } from '@/common/validator';
import { register } from '@/api/login';
import { mapActions, mapGetters } from 'vuex';
import agreement from '@/views/agreement/index.vue';
import md5 from 'js-md5';
export default {
  name: 'modify',
  components: { getVerificationCode, agreement },
  props: {
    mobile: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    const passwordCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        mobile: '',
        password: '',
        confirmPassword: '',
        verifyCode: '',
      },
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhoneSimple, trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur' },
        ],
        confirmPassword: [{ required: true, validator: passwordCheck, trigger: 'blur' }],
        verifyCode: [{ required: true, message: '请输入手机验证码', trigger: 'blur' }],
      },
      tips: '获取验证码',
      isClick: false,
      saveLoading: false,
      agreest: false, // 是否同意平台使用协议
      dialogVisibleProtocol: false,
      iframeSrc: '',
      dialogTitle: '',
      mobileFlag: false,
      agreementType: '',
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  created() {
    this.ruleForm.mobile = this.mobile || '';
  },
  methods: {
    ...mapActions(['GetAuthenticateInfo']),
    submitForm(formName) {
      if (!this.agreest) {
        this.$message.info('请勾选并阅读同意协议');
        return;
      }
      const postData = {
        ...this.ruleForm,
        password: md5(this.ruleForm.password),
        confirmPassword: md5(this.ruleForm.confirmPassword),
      };
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.saveLoading = true;
          await this.mobileCheck();
          if (this.mobileFlag) {
            register(postData)
              .then(res => {
                if (res.code === '0') {
                  const data = {
                    username: this.ruleForm.mobile,
                    password: this.ruleForm.password,
                  };
                  this.$store
                    .dispatch('Login', data)
                    .then(loginRes => {
                      return this.GetAuthenticateInfo();
                    })
                    .then(res => {
                      const parameter = {
                        channel: 'MERCHANT_PC',
                        id: this.userInfo.memberId,
                      };
                      memberSourceCreate(parameter).then(res => {});
                      this.$emit('registerEnd');
                    });
                }
              })
              .finally(() => {
                this.saveLoading = false;
              });
          } else {
            this.saveLoading = false;
          }
        } else {
          return false;
        }
      });
    },
    // async getCode() {
    //   if (!this.ruleForm.mobile || !/^1\d{10}$/.test(this.ruleForm.mobile)) {
    //     this.$message.error('请正确输入手机号');
    //     return;
    //   }
    //   this.isClick = true;
    //   await this.mobileCheck();
    //   if (!this.mobileFlag) {
    //     let sec = 59;
    //     this.t = setInterval(() => {
    //       this.tips = `${sec}s`;
    //       if (!sec || sec < 1) {
    //         clearInterval(this.t);
    //         this.tips = '获取验证码';
    //         this.isClick = false;
    //       }
    //       sec--;
    //     }, 1000);
    //     getCode(this.ruleForm.mobile).then(response => {
    //       this.$message.success('发送成功');
    //     });
    //   } else {
    //     this.isClick = false;
    //   }
    // },
    openProtocol(type) {
      const typeObj = {
        xy: 'PLATFORM_AGREEMENT',
        gf: 'DISTRIBUTOR_MANAGEMENT',
        ys: 'PRIVACY_AGREEMENT',
      };
      const titleObj = {
        xy: '平台使用协议',
        gf: '分销商管理规范',
        ys: '隐私协议',
      };
      this.agreementType = typeObj[type];
      this.dialogTitle = titleObj[type];
      this.dialogVisibleProtocol = true;
    },
    // 是否注册
    async mobileCheck() {
      const p = {
        mobile: this.ruleForm.mobile,
        verifyType: 'REGISTER', // LOGIN:手机号登录验证;REGISTER:手机号注册验证,可用值:LOGIN,REGISTER
      };
      let flag = true;
      await memberAuthMobileVerify(p).catch(() => {
        flag = false;
      });
      this.mobileFlag = flag;
      return flag;
    },
  },
};
</script>
<style lang="scss" scoped>
.ruleForm {
  .el-form-item {
    width: 450px;
    /deep/.el-form-item__label {
      height: 40px;
      line-height: 40px;
      color: #333;
      font-size: 18px;
      font-weight: 400;
      &::before {
        content: '';
      }
    }
    .el-input {
      width: 350px;
      height: 40px;
      /deep/.el-input__inner {
        height: 40px;
      }
    }
  }
  &-mobile {
    /deep/.el-input__inner {
      padding-left: 60px;
    }
    &-text {
      position: absolute;
      left: 0;
      top: 8px;
      width: 46px;
      height: 24px;
      border-right: 1px solid #e6e7eb;
      text-align: center;
      line-height: 24px;
      font-size: 14px;
      color: #999;
    }
  }
  &-verifyCode {
    &-text {
      width: 94px;
      height: 24px;
      line-height: 24px;
      padding: 0;
      position: absolute;
      top: 9px;
      right: 0;
      color: #ab0033;
      border-left: 1px solid #e6e7eb;
      font-size: 14px;
    }
  }
  &-text {
    padding-left: 100px;
    color: #999;
    margin-bottom: 6px;
  }
  &-row {
    padding-left: 100px;
    margin: 32px 0 16px 0;
  }
  &-btn {
    width: 350px;
    height: 48px;
    font-size: 18px;
  }
  &-textBox {
    height: 70vh;
    overflow: auto;
    text-align: justify;
    .mainBox {
      padding: 0 10px 50px 10px;
      .mainBox-title {
        font-weight: 600;
        font-size: 14px;
        margin: 5px 0;
        text-align: center;
        display: block;
      }
    }
  }
}
</style>
