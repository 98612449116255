<template>
  <div>
    <div class="getVerificationCode" @click="sendCodeFn">
      {{ text }}
    </div>
    <yidun-captcha ref="captcha" :isSense="true" @verify="handleCaptcha"></yidun-captcha>
  </div>
</template>

<script>
// 验证码倒计时组件
const COUNT_NUM = 60; // 倒计时秒数
import YidunCaptcha from '@/components/YidunCaptcha';
import { sendVerifyCodeWithActionCode } from '@/api/system/user';
import { debounce } from '@/utils/enhance';
export default {
  name: 'getVerificationCode',
  components: {
    YidunCaptcha,
  },
  data() {
    return {
      count: COUNT_NUM,
      flag: true,
      timer: null,
      captchaId: process.env.VUE_APP_CAPTCHAID,
    };
  },
  created() {},
  props: {
    mobile: {
      type: String,
    },
    handleCallback: {
      type: Function,
    },
    // 发送验证码之前需要执行的函数
    validFunction: {
      type: Function,
      default: null,
    },
  },
  computed: {
    text() {
      if (this.count === 60) {
        return '获取验证码';
      }
      return `${this.count}s`;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer); // 页面销毁时回收定时器
  },
  methods: {
    tryToVerify() {
      if (this.$refs.captcha) {
        this.$refs.captcha.verify();
      }
    },
    handleCaptcha(validate) {
      this.tryReset();
      if (this.flag) {
        this.flag = false;
        sendVerifyCodeWithActionCode({
          mobile: this.mobile.trim(),
          actionCodeCmd: {
            captchaId: this.captchaId,
            user: '',
            validate,
          },
        })
          .then(data => {
            if (data.success) {
              this.$message.success('发送成功');
              this.timer = setInterval(() => {
                this.count--;
                if (this.count === 0) {
                  this.count = COUNT_NUM;
                  clearInterval(this.timer);
                  this.flag = true;
                }
              }, 1000);
            } else {
              this.flag = true;
            }
          })
          .catch(() => {
            this.flag = true;
          });
      }
    },
    tryReset() {
      this.$refs.captcha.reset();
    },
    sendCodeFn() {
      if (!this.flag) return;
      if (!this.mobile || !/^1\d{10}$/.test(this.mobile.trim())) {
        this.$message.error('请正确输入手机号');
        return;
      }
      if (this.validFunction) {
        this.validFunction().then(res => {
           this.sendCode();
          });
      } else {
        this.sendCode();
      }
    },
    sendCode: debounce(function () {
      if (this.handleCallback) {
        this.handleCallback().then(bool => {
          bool && this.tryToVerify();
        });
      } else {
        this.tryToVerify();
      }
    }, 1500),
  },
};
</script>

<style scoped lang="scss">
.getVerificationCode {
  // font-size: 30rpx;
  cursor: pointer;
  text-align: center;
}
</style>
