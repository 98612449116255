<template>
  <div class="page" v-if="isLogin">
    <div class="page-content">
      <el-steps :active="stepsActive" class="steps">
        <el-step description title="选择客户类型"></el-step>
        <el-step description title="填写资料"></el-step>
        <el-step description title="提交审核"></el-step>
        <el-step description title="平台审核"></el-step>
      </el-steps>
      <main class="page-main">
        <template v-if="pageid !== page_await">
          <div
            class="page-start-box box"
            v-show="pageid === page_customer_type"
          >
            <ul class="customer-type">
              <li
                @click="selectMerchantType('PERSONAL')"
                class="customer-type__item"
                :class="{ active: merchantType === 'PERSONAL' }"
              >
                <div class="item-content">
                  <div class="item-content__info">
                    <img :src="personalIcon" />
                    <h3>个人账号注册</h3>
                  </div>
                  <ul class="item-content__list">
                    <li>
                      <span>无企业营业执照</span>
                    </li>
                    <li>
                      <span>个人实名认证</span>
                      <span>买家身份采购</span>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                @click="selectMerchantType('ENTERPRISE')"
                class="customer-type__item"
                :class="{ active: merchantType === 'ENTERPRISE' }"
              >
                <div class="item-content">
                  <div class="item-content__info">
                    <img :src="enterpriseIcon" />
                    <h3>企业账号注册</h3>
                  </div>
                  <ul class="item-content__list">
                    <li>
                      <span>有企业营业执照（含个体工商户）</span>
                    </li>
                    <li>
                      <span>企业实名认证</span>
                      <span>买家身份采购</span>
                      <span>卖家身份开店</span>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <el-button
              @click="submitMerchantType"
              type="primary"
              class="next-btn"
              >下一步</el-button
            >
          </div>

          <div class="page-start-box box" v-show="pageid === page_mobile">
            <RegisterForm @registerEnd="registerEnd" :mobile="mobile" />
          </div>

          <div class="page-start-box box" v-show="pageid === page_channel">
            <h4 class="title">您的经营渠道是？</h4>
            <div class="content">
              <h4 class="content-title">线上渠道</h4>
              <ul class="tabsbox">
                <li
                  :class="item.checked ? 'active' : ''"
                  :key="item.id"
                  @click="onlineClick(index)"
                  class="tabs"
                  v-for="(item, index) of onlineList"
                >
                  {{ item.label }}
                </li>
              </ul>
            </div>
            <div class="content" v-if="otherChanne">
              <h4 class="content-title">其他渠道名称</h4>
              <div class="otherChanne-box">
                <el-input
                  placeholder="请输入内容"
                  v-for="(item, index) in otherChanneList"
                  :key="index"
                  v-model="item.text"
                  class="otherChanne-item"
                >
                  <template slot="prepend" v-if="index !== 0">
                    <img
                      class="otherChanne-del"
                      src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/mp/step/del.png"
                      alt=""
                      @click="delOtherChanne(index)"
                    /> </template
                ></el-input>
                <div
                  class="otherChanne-add"
                  @click="addOtherChanne"
                  v-show="otherChanneList.length < 10"
                >
                  <img
                    src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/mp/step/add.png"
                  />
                  继续添加
                </div>
              </div>
            </div>
            <div class="content">
              <h4 class="content-title">线下渠道</h4>
              <ul class="tabsbox">
                <li
                  :class="item.checked ? 'active' : ''"
                  :key="item.id"
                  @click="offlineClick(index)"
                  class="tabs"
                  v-for="(item, index) of offlineList"
                >
                  {{ item.label }}
                </li>
              </ul>
            </div>
            <el-button @click="nextStep(page_know)" type="primary" class="next-btn"
              >下一步</el-button
            >
          </div>

          <div class="page-start-box box" v-show="pageid === page_know">
            <h4 class="title">
              您是通过什么渠道了解我们的？
              <span @click="back(page_channel)" class="back">返回修改</span>
            </h4>
            <div class="content">
              <h4 class="content-title">了解渠道</h4>
              <ul class="tabsbox">
                <li
                  :class="item.checked ? 'active' : ''"
                  :key="item.id"
                  @click="knowClick(index)"
                  class="tabs"
                  v-for="(item, index) of knowList"
                >
                  {{ item.label }}
                </li>
              </ul>
            </div>
            <el-button @click="nextStep(page_materials)" type="primary" class="next-btn"
              >下一步</el-button
            >
          </div>

          <div class="page-start-box box" v-show="pageid === page_materials">
            <h4 class="title">
              提交材料
              <span v-if="supplementStatus!=='NO'" @click="back(page_know)" class="back">返回修改</span>
            </h4>
            <div class="content">
              <StepForm
                :merchantType="merchantType"
                :supplementStatus="supplementStatus"
                :channelType="channelType"
                :onlineList="onlineList"
                @emitFormData="stepFormData"
                ref="StepForm"
              />
            </div>
          </div>
        </template>
        <div class="page-start-box box" v-show="pageid === page_await">
          <div class="icon-time-box">
            <i v-if="isPersonalPass" class="el-icon-success successicon"></i>
            <i v-else class="el-icon-time timeicon"></i>
          </div>
          <div class="content causebox">
            <template v-if="isPersonalPass">
              <h5 class="cause">注册成功，欢迎您加入水羊直供</h5>
              <el-button @click="goHome" type="primary">去逛逛</el-button>
            </template>
            <template v-else>
              <h5 class="cause">{{ causeObj.cause }}</h5>
              <p class="cause-txt">{{ causeObj.causeTxt }}</p>
              <el-button @click="edit()" type="primary">去补充资料</el-button>
            </template>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  getDicListByType,
  submitCreate,
  reSubmit,
  getMyInfo,
  distributorSupplementInfo
} from '@/api/step';
import StepForm from '@/components/views/step/StepForm';
import RegisterForm from './components/RegisterForm';
import { mapGetters, mapActions } from 'vuex';
import { getItem, removeItem } from '@/utils/cookie';
import { throttle } from '@/utils/enhance';
import {
  getDevelopCustomerServiceParams,
} from '@/common/localStorage';

export default {
  name: '',
  components: { RegisterForm, StepForm },
  data() {
    return {
      isLogin: true,
      // dedicated: {}, // 专属客服
      offlineList: [], // 线下经营渠道
      onlineList: [], //  线上经营渠道
      knowList: [], // 了解渠道
      channelInfo: '', // 渠道名称
      channelType: '', // 经营渠道：线上-ONLINE,线下-OFFLINE = ['ONLINE', 'OFFLINE']
      knowChannel: '', // 了解渠道
      pageid: '0', // 初始页面
      page_status: null, // WAIT_AUDIT-待审核，PASS-审核通过,REJECT-审核未通过
      page_customer_type: '0', // 客户类型
      page_mobile: '1', // 手机验证
      page_channel: '2', // 经营渠道ID
      page_know: '3', // 了解渠道ID
      page_materials: '4', // 提交资料ID
      page_await: '5', // 等待审核/审核不通过
      timeout: null,
      id: null, // 认证ID
      otherChanne: false, // 其他渠道是否显示
      otherChanneList: [{ text: '' }],
      otherChannelInfo: [], // 其他渠道参数
      mobile: '',
      merchantType: '', // 商家类型
      enterpriseIcon: `${process.env.VUE_APP_MUSHUROOMFILEURL}/static/file/soyoung-zg/common/register-icon-enterprise.png`,
      personalIcon: `${process.env.VUE_APP_MUSHUROOMFILEURL}/static/file/soyoung-zg/common/register-icon-personal.png`,
      supplementStatus: '',
      isPersonalPass: false,
    };
  },
  created() {
    this.initQuery();
    this.getDicListByType();
    this.init();
  },
  computed: {
    stepsActive() {
      return parseInt(this.pageid);
    },
    causeObj() {
      const data = {
        cause:
          this.page_status === 'REJECT'
            ? '您的账号审核未通过'
            : '您的账号正在审核中，请耐心等待~ ',
        causeTxt:
          this.page_status === 'REJECT'
            ? `失败原因：${this.approveStatus.approveMsg}`
            : '如有疑问请联系你的专属顾问'
      };
      return data;
    },
    ...mapGetters(['userInfo'])
  },
  methods: {
    ...mapActions(['user', 'GetAuthenticateInfo']),
    // 初始化参数
    initQuery(){
      const { mobile, supplementStatus = '' } = this.$route.query;
      if (mobile) {
        this.mobile = mobile;
      }
      if(supplementStatus){
        this.supplementStatus = supplementStatus;
      }
    },
    init() {
      if (this.userInfo?.mobile) {
        this.pageid = '1';
        this.GetAuthenticateInfo()
          .then(() => {
            const { supplementStatus = '' } = this;
            const approveStatus = this.$store.getters.approveStatus || getItem('aS'); // 是否认证
            this.approveStatus = approveStatus;
            this.page_status = !approveStatus ? null : approveStatus.status;
            if (!approveStatus) {
              this.pageid = this.page_channel;
            } else {
              if (approveStatus.status === 'PASS') {
                if(supplementStatus){
                  if(supplementStatus === 'NO'){
                    this.pageid = this.page_materials;
                    this.getMyInfo();
                  }
                  if(supplementStatus === 'WAIT'){
                    this.pageid = this.page_await;
                  }
                  return;
                }
                // 个人账号直接跳转首页判断
                if(approveStatus.merchantType === 'PERSONAL'){
                  this.pageid = this.page_await;
                  this.isPersonalPass = true;
                  return;
                }
                if (getItem('toUrl')) {
                  this.$router.replace(getItem('toUrl'));
                  removeItem('toUrl');
                } else {
                  this.$router.replace('/');
                }
              } else {
                this.pageid = this.page_await;
              }
            }
          })
          .catch(error => {
            const { data } = error;
            if (data.code === '400') {
              this.isLogin = false;
            }
          });
      }
    },
    stepFormData(data) {
      const rep = this.page_status === 'REJECT' ? reSubmit : this.supplementStatus === 'NO' ? distributorSupplementInfo : submitCreate;
      const repData = {
        ...data,
        channelInfo: this.channelInfo,
        channelType: this.channelType,
        knowChannel: this.knowChannel,
        type: 'DISTRIBUTE'
      };
      // 判断是否从拓展顾问推广码来的
      const params = getDevelopCustomerServiceParams();
      const developCSId = params?.developCSId || '';
      if (developCSId) {
        repData.developCSId = developCSId;
      }
      if (this.id) repData.id = this.id;
      if (this.channelInfo.includes('OTHER')) {
        repData.otherChannelInfo = this.otherChannelInfo;
      }
      rep(repData).then(() => {
        this.$message.success('提交成功');
        this.$router.replace({ query: {} });
        window.location.reload();
        this.init();
        this.back(this.page_await);
      });
    },
    async edit() {
      // 编辑
      throttle(() => {
        this.back(this.page_channel);
        this.getMyInfo();
      }, 3000)();
    },
    back(pageid) {
      this.switchover(pageid);
    },
    // 经营渠道线上
    onlineClick(index) {
      this.offlineList.forEach(i => {
        i.checked = false;
      });
      this.onlineList[index].checked = !this.onlineList[index].checked;
      let flag = false;
      for (const item of this.onlineList) {
        if (item.value === 'OTHER' && item.checked) {
          flag = true;
          break;
        }
      }
      this.otherChanne = flag;
    },
    // 经验渠道线下
    offlineClick(index) {
      this.offlineList.forEach(i => {
        i.checked = false;
      });
      this.onlineList.forEach(i => {
        i.checked = false;
      });
      this.offlineList[index].checked = true;
    },
    // 渠道
    knowClick(index) {
      this.knowList.forEach(i => {
        i.checked = false;
      });
      this.knowList[index].checked = true;
    },
    nextStep(pageid) {
      // 下一步
      if (this.timeout !== null) return;
      if (pageid === this.page_know) {
        const typeObj = {
          soyoungzg_channel_offline: 'OFFLINE',
          soyoungzg_channel_online: 'ONLINE'
        };
        const value = [];
        let flag = false;
        for (const i of this.onlineList) {
          if (i.checked) {
            flag = true;
            this.channelType = typeObj[i.type];
            value.push(i.value);
          }
        }
        for (const i of this.offlineList) {
          if (i.checked) {
            flag = true;
            this.channelType = typeObj[i.type];
            value.push(i.value);
          }
        }
        if (flag) {
          this.channelInfo = value;
          if (this.channelInfo.includes('OTHER')) {
            let arrFlag = false;
            for (const item of this.otherChanneList) {
              if (item.text) {
                arrFlag = true;
              }
            }
            if (!arrFlag) {
              this.$message({
                message: '请填写至少一个其他渠道名称',
                type: 'warning'
              });
              return;
            }
            this.otherChannelInfo = this.otherChanneList.map(item => {
              return item.text;
            });
            this.otherChannelInfo = this.otherChannelInfo.filter(item => item);
          }
          this.switchover(pageid);
        } else {
          this.$message({
            message: '请选择经营渠道',
            type: 'warning'
          });
        }
      } else if (pageid === this.page_materials) {
        let flag = false;
        for (const i of this.knowList) {
          if (i.checked) {
            flag = true;
            this.knowChannel = i.value;
          }
        }
        if (flag) {
          this.switchover(pageid);
        } else {
          this.$message({
            message: '请选择了解渠道',
            type: 'warning'
          });
        }
      }
    },
    switchover(pageid) {
      this.pageid = null;
      if (this.timeout) return;
      this.timeout = setTimeout(() => {
        this.pageid = pageid;
        this.timeout = null;
      }, 1000);
    },
    maplist(list, tab) {
      return list.map(item => ({
        ...item,
        checked: item.value === tab.value
      }));
    },
    getDicListByType() {
      // 获取经营渠道
      const q1 = getDicListByType('soyoungzg_channel_offline'); // // 线下经营渠道
      const q2 = getDicListByType('soyoungzg_channel_online'); // 线上经营渠道
      const q3 = getDicListByType('soyoungzg_know_channel'); // 了解经营渠道
      Promise.all([q1, q2, q3]).then(([q1, q2, q3]) => {
        q1.data.forEach(item => {
          item.checked = false;
        });
        q2.data.forEach(item => {
          item.checked = false;
        });
        q3.data.forEach(item => {
          item.checked = false;
        });
        this.offlineList = q1.data || [];
        this.onlineList = q2.data || [];
        this.knowList = q3.data || [];
      });
    },
    //  获取我的认证信息
    async getMyInfo() {
      const data = await getMyInfo();
      if (data.success) {
        const {
          channelType,
          id,
          channelInfo,
          knowChannel,
          otherChannelInfo = []
        } = data.data;
        this.id = id;
        this.channelType = channelType;
        this.channelInfo = channelInfo || [];
        this.knowChannel = knowChannel;
        if (this.channelType === 'ONLINE') {
          this.channelInfo.forEach(item => {
            this.onlineList = this.maplist(this.onlineList, { value: item });
          });
          if (this.channelInfo.includes('OTHER')) {
            this.otherChanneList = otherChannelInfo.map(item => {
              return { text: item };
            });
            this.otherChanne = true;
          }
        } else {
          this.offlineList = this.maplist(this.offlineList, {
            value: channelInfo[0]
          });
        }
        this.knowList = this.maplist(this.knowList, { value: knowChannel });
        this.$refs['StepForm'].setData(data.data);
      }
    },
    logout() {
      if (process.env.VUE_APP_USE_SSO_DEFAULT) {
        location.href = '/syoung/sso-logout';
      } else {
        location.href = '/syoung/logout';
      }
    },
    addOtherChanne() {
      if (this.otherChanneList.length > 9) return;
      this.otherChanneList.push({ text: '' });
    },
    delOtherChanne(index) {
      this.otherChanneList.splice(index, 1);
    },
    registerEnd() {
      this.pageid = '2';
    },
    // 选择客户类型
    selectMerchantType(type) {
      this.merchantType = type;
    },
    // 选择客户类型下一步
    submitMerchantType() {
      if (!this.merchantType) {
        this.$message.error('请选择客户类型');
        return;
      }
      this.pageid = this.page_mobile;
    },
    // 去首页
    goHome(){
      this.$router.replace('/');
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
ul,
li {
  list-style-type: none;
}
.page {
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  min-height: 70vh;
}
.page-content {
  background: #fff;
  padding: 25px;
  width: 1180px;
  margin: 0 auto 40px;
  flex: 1;
  .page-header {
    margin-bottom: 15px;
    position: relative;
    .buttonbox {
      position: absolute;
      right: 0;
      top: -14px;
    }
  }
  .page-main {
    padding: 60px 120px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    .page-start-box {
      padding-bottom: 50px;
    }
    .box {
      position: relative;
      .title {
        font-size: 24px;
        font-weight: 700;
        position: relative;
        margin-bottom: 35px;
        .back {
          color: #568ae8;
          position: absolute;
          right: 0;
          top: 10px;
          font-size: 13px;
          cursor: pointer;
        }
      }
      .icon-time-box {
        text-align: center;
        margin-bottom: 16px;
        .timeicon {
          font-size: 80px;
          color: #ab0033;
          margin-top: 90px;
        }
        .successicon {
          font-size: 80px;
          color: #07C160;
          margin-top: 90px;
        }
      }
      .content {
        margin-bottom: 35px;
        .content-title {
          font-size: 18px;
          font-weight: 700;
        }
        .tabsbox {
          display: flex;
          flex-wrap: wrap;
          .tabs {
            flex-shrink: 0;
            width: 92px;
            text-align: center;
            border-radius: 4px;
            background: #f2f2f2;
            height: 36px;
            line-height: 36px;
            margin: 18px 25px 0 0;
            transition: all 0.3s;
            cursor: pointer;

            &:hover,
            &.active {
              background: #ab0033;
              color: #fff;
            }
          }
        }
      }
      .next-btn {
        width: 350px;
        height: 48px;
        font-size: 18px;
        display: block;
        margin: 70px auto 0;
      }
      .causebox {
        text-align: center;
        .cause {
          font-size: 20px;
          text-align: center;
          margin-bottom: 16px;
        }
        .cause-txt {
          text-align: center;
          font-size: 14px;
        }
        .el-button {
          margin-top: 12px;
          width: 350px;
          height: 48px;
          font-size: 18px;
        }
      }
    }
  }
}
.otherChanne {
  &-box {
    margin-top: 18px;
  }
  &-item {
    margin-bottom: 10px;
  }
  &-del {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  &-add {
    display: flex;
    width: 100px;
    align-items: center;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }
}
.titleText {
  color: #ab0033;
}
.steps {
  /deep/ .el-step__icon {
    width: 66px;
    height: 66px;
    font-size: 26px;
    border: 2px dashed;
    font-weight: 600;
  }
  /deep/ .el-step__line {
    top: 32px;
    background-color: transparent;
    &::before {
      width: 100%;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      border-bottom: 2px dashed #ccc;
      z-index: 1;
    }
    .el-step__line-inner {
      border: none;
      border-bottom: 2px dashed #ab0033;
      border-width: 2px !important;
      z-index: 1;
      position: absolute;
    }
  }
}
.customer-type {
  display: flex;
  margin-bottom: 24px;
  &__item {
    width: 343px;
    margin: 0 30px;
    cursor: pointer;
    .item-content {
      height: 144px;
      padding: 22px 20px;
      border-radius: 9px;
      border: 1px solid #ececec;
      background: linear-gradient(
        114deg,
        #ffeaf1 3.89%,
        #fff 18.27%,
        rgba(255, 255, 255, 0) 65.23%
      );
      &__info {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        img {
          width: 41px;
          margin-right: 10px;
        }
        h3 {
          flex: 1;
          font-size: 16px;
          font-weight: bold;
          color: #333;
        }
      }
      &__list {
        li {
          display: flex;
          align-items: center;
          &:not(:last-child) {
            margin-bottom: 5px;
          }
          span {
            &:not(:last-child) {
              margin-right: 14px;
            }
            display: flex;
            align-items: center;
            color: #666;
            font-size: 12px;
            line-height: 17px;
            &:before {
              margin-right: 10px;
              content: "";
              width: 5px;
              height: 5px;
              background: #ab0033;
              border-radius: 50%;
            }
          }
        }
      }
    }
    &.active {
      .item-content {
        border-color: #ab0033;
      }
    }
  }
}
</style>
