<template>
  <!-- 协议详情 -->
  <div class="wrap" v-loading="loading" v-horizontal-scroll>
    <div v-if="type" class="customerCaseDetail" v-wechat-title="title"></div>
    <div v-if="agreementType === 'CREDIT_AGREEMENT'">
      <div v-html="disclaimerContent"></div>
    </div>
    <template v-else v-for="(item, index) of contentArray">
      <text-ad
        :key="index"
        v-if="item.type === 'TextInput'"
        :content="item.value.text"
        :borderTop="item.value.borderTop"
        :borderBottom="item.value.borderBottom"
        :siderSpace="item.value.siderSpace"
        :innerSpace="item.value.innerSpace"
        :lineHeight="item.value.lineHeight"
        :fontWeight="item.value.fontWeight"
        :fontSize="item.value.fontSize"
        :letterSpacing="item.value.letterSpacing"
        :color="item.value.color"
        :background-color="item.value.backgroundColor"
        :text-align="item.value.textAlign"
      ></text-ad>
      <div v-if="item.type === 'RichText'" :key="index" v-html="item.value.content"></div>
    </template>
  </div>
</template>
<script>
import textAd from '@/components/micro-page/components/text-ad'; // 文本组件
import { featureGetDetail, featureListByCategory, disclaimerGetByTypeForApp } from '@/api/configure';
export default {
  name: 'agreement',
  components: { textAd },
  data() {
    return {
      loading: false,
      contentArray: [],
      categorylist: [],
      disclaimerContent: '',
      title: '',
      option: {
        specification: {
          name: '分销商管理规范',
          key: 'DISTRIBUTOR_MANAGEMENT',
        },
        service: {
          name: '平台使用协议',
          key: 'PLATFORM_AGREEMENT',
        },
        privacy: { name: '隐私协议', key: 'PRIVACY_AGREEMENT' },
      },
      type: '',
      current: {}, // 当前协议
    };
  },
  props: {
    agreementType: {
      type: String,
    },
  },
  watch: {
    agreementType() {
      if (this.agreementType === 'CREDIT_AGREEMENT') {
        this.getDisclaimerData();
      }else {
        this.contentData = [];
        this.featureListByCategory();
      }
    },
  },
  created() {
    const { type } = this.$route.query;
    if (type && ['specification', 'service', 'privacy'].includes(type)) {
      this.type = this.option[type]['key'];
    }
    if (this.agreementType === 'CREDIT_AGREEMENT') {
        this.getDisclaimerData();
      }else {
        this.featureListByCategory();
      }
  },
  computed: {},
  methods: {
    getDisclaimerData() {
      disclaimerGetByTypeForApp('CREDIT_AGREEMENT').then(res => {
        if (res && res.data && res.data.featureItemJson) {
          const { content = '' } = JSON.parse(res.data.featureItemJson);
          this.disclaimerContent = content;
        }
      });
    },
    featureGet(id) {
      featureGetDetail(id)
        .then(res => {
          if (res.success) {
            if (res.data) {
              const { title, content } = res.data;
              let contentArray = [];
              try {
                contentArray = JSON.parse(content);
              } catch (e) {
                throw new Error('content不为JSON格式，数据解析错误!');
              }
              this.title = title;
              this.contentArray = contentArray.map(item => ({
                ...item,
                value: item.value ? item.value : {},
              }));
            }
          } else {
            alert(res.msg);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    featureListByCategory() {
      let featureTypes = ['PRIVACY_AGREEMENT', 'DISTRIBUTOR_MANAGEMENT', 'PLATFORM_AGREEMENT']; // PRIVACY_AGREEMENT-隐私协议,DISTRIBUTOR_MANAGEMENT-分销商管理规范、PLATFORM_AGREEMENT-平台使用协议、
      let category = this.type;
      if (this.agreementType) {
        featureTypes = [this.agreementType];
        category = this.agreementType;
      }
      const ps = {
        featureTypes,
        status: '2', // 1-暂存，2-发布,3-已作废
      };
      this.loading = true;
      featureListByCategory(ps)
        .then(res => {
          this.categorylist = res.data || [];
          const current = this.categorylist.find(item => item.category === category);
          this.current = current;
          this.featureGet(this.current.id);
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
html {
  min-width: 100%;
}
.wrap {
  width: 100%;
  height: 50vh;
  overflow: auto;
  position: relative;
}

.user-defined-100 {
  width: 100% !important;
}

.user-defined-90 {
  width: 90% !important;
}

.user-defined-80 {
  width: 80% !important;
}

.user-defined-70 {
  width: 70% !important;
}

.user-defined-60 {
  width: 60% !important;
}

.user-defined-50 {
  width: 50% !important;
}

.user-defined-40 {
  width: 40% !important;
}

.user-defined-30 {
  width: 30% !important;
}

.user-defined-20 {
  width: 20% !important;
}

.user-defined-10 {
  width: 10% !important;
}
</style>
