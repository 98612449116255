<!-- 网易易盾 行为式验证码组件 -->
<template>
  <div id="captcha"></div>
</template>

<script>
import initNECaptchaWithFallback from './yidun-captcha.js';
export default {
  name: 'YidunCaptcha',
  data() {
    return { captchaIns: null };
  },

  components: {},

  computed: {},

  created() {},

  mounted() {
    this.init();
  },
  destroyed() {
    this.captchaIns = null;
  },
  props: {
    isSense: {
      default: false,
      type: Boolean,
    },
    captchaId: String,
    width: {
      type: [String, Number],
      default: 320,
    },
    lang: {
      type: String,
      default: 'zh-CN',
    },
    customStyles: {
      type: Object,
      default: () => ({}),
    },
    isHideCloseBtn: Boolean, // 仅限客户端
  },
  methods: {
    init() {
      // h5其他配置项请参考文档
      const options = {
        captchaId: process.env.VUE_APP_CAPTCHAID,
        width: this.width,
        lang: this.lang,
        element: '#captcha',
        protocol: 'https',
        enableClose: this.isHideCloseBtn,
        customStyles: this.customStyles,
        onVerify: (err, data) => {
          !err && this.handleVerify(data.validate);
        },
      };
      if (this.isSense) {
        options.mode = 'bind';
      } else {
        options.mode = 'popup';
      }
      // eslint-disable-next-line no-undef
      initNECaptchaWithFallback(
        { ...options },
        ins => {
          this.captchaIns = ins;
          //console.log('初始化成功', ins);
        },
        err => {
          console.log('初始化失败', err);
          this.handleError(err);
        }
      );
      //  console.log(process.env.VUE_APP_CAPTCHAID);
    },
    verify() {
      if (this.isSense) {
        this.captchaIns.verify();
      } else {
        this.captchaIns.popUp();
      }
    },
    reset() {
      this.captchaIns.refresh();
    },
    destroyCap() {
      this.captchaIns.destroy();
    },
    handleError({ code, msg, message } = {}) {
      // eslint-disable-next-line no-unused-vars
      const err = new Error(msg ? `Error ${code}"${msg}` : message);
      this.$emit('captchaError');
    },
    handleVerify(validate) {
      this.$emit('verify', validate);
    },
  },
};
</script>
<style lang='scss' scoped>
</style>